import axios from "axios";
// import { hideLoading } from '@/utils/index'
import { getToken } from "/@/utils/auth"; // getExpiresTimestamp
// import { getLocalMsTime } from '@/utils/index.js'
import { gotoAuthCenter, logoutApi } from "/@/api/auth-token";
// import i18n from '@/i18n'
import { transformyI18n } from "/@/plugins/i18n";
import { ElMessage, ElMessageBox } from "element-plus";
import { showConfirm, showConfirm2 } from "/@/utils/showBox";
import $store from "/@/store/index";
import { storageLocal } from "/@/utils/storage";
import { useRouter, useRoute } from "vue-router";
import { setStateCodeKey, getLanguage } from "/@/utils/auth";
import { useUserStoreHook } from "/@/store/modules/user";

const router = useRouter();
// create an axios instance
const service = axios.create({
  baseURL: window._CONFIG["baseURL"],
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});

// 带token请求拦截器
function requestInterceptor(data) {
  // do something before request is sent
  const config = Object.assign({}, data);
  const token = getToken();
  // const expiresTimestamp = getExpiresTimestamp()
  config.headers["Authorization"] = `Bearer ${token}`;
  let local = "zh_CN";
  if (storageLocal.getItem("responsive-locale")) {
    local = storageLocal.getItem("responsive-locale")?.locale ?? "zh";
    if (local == "zh") local = "zh_CN";
    if (local == "en") local = "en_US";
  }
  config.headers["local"] = local;

  const acquirerId = $store.getters.acquirerId;
  const role = $store.getters.selectedRole;
  const type = $store.getters.type;
  const customerId = $store.getters.customerId;
  const storeId = $store.getters.storeId;

  // console.log('===========customerId',customerId);


  if (acquirerId) {
    config.headers["acquirer-id"] = acquirerId;
  }
  if (role) {
    config.headers["X-User-Role"] = role;
  }
  if (type) {
    config.headers["X-App-Type"] = type;
  }
  // console.log(customerId, storeId, role);

  delete config.headers["X-User-CustomerId"];
  delete config.headers["X-User-StoreId"];
  if (customerId) {
    config.headers["X-User-CustomerId"] = customerId;
    // role === ("koms.customer.admin" || "koms.customer.manage")
    //   ? customerId
    //   : "";
  }
  // if (storeId) {
  //   config.headers["X-User-StoreId"] = storeId;
  // }
  if (storeId) {
    // config.headers["X-User-StoreId"] =
    //   role === "koms.store.admin" ||
    //   role === "koms.store.manage" ||
    //   role === "koms.store.op"
    //     ? storeId
    //     : "";
    config.headers["X-User-StoreId"] =
    role.includes("koms.store")
      ? storeId
      : "";
  }
  // if (role === "koms.storeId.admin") {
  //   config.headers["X-User-StoreId"] = storeId;
  // }

  // if (token && expiresTimestamp > getLocalMsTime() - 300 * 1000) {
  //   config.headers['Authorization'] = `Bearer ${token}`
  // } else {
  //   // store.dispatch('user/resetToken')
  //   // router.push('/401')
  //   // return Promise.reject(new Error('未授权,请重新登录'))
  // }
  return config;
}
// 不带token请求拦截器
function requestInterceptorWithoutToken(config) {
  return config;
}
let isb = true;
let isb2 = true;
function changeIsb() {
  setTimeout(() => {
    isb = true;
  }, 2000);
}
function changeIsb2() {
  setTimeout(() => {
    isb2 = true;
  }, 2000);
}
function handleAuthError(res) {
  if (res && res.code === 40001 && isb) {
    isb = false;
    // $store.dispatch("user/resetToken");
    showConfirm(
      () => {
        // $store.dispatch("user/resetToken");
        $store.dispatch("user/logout");
      },
      transformyI18n("温馨提示"),
      transformyI18n("需要您重新登录！")
    );

    changeIsb();
  } else if (res.code === 40002 && isb) {
    isb = false;
    router.push({ path: '/401' })
    changeIsb();
  } else if (res.code === 40000 && isb) {
    isb = false;
    ElMessage.warning(transformyI18n("登录超时"));
    $store.dispatch("user/resetToken");
    $store.dispatch("user/logout");
    changeIsb();
  } else if (res.code && res.code !== 20000 && isb) {
    isb = false;
    let errorMessage = res.message;
    if (errorMessage == "timeout of 30000ms exceeded") {
      errorMessage = transformyI18n("outTime");
    }
    ElMessage.error(transformyI18n(errorMessage) || "Error");
    changeIsb();
  } else if (res && res.code !== 20000) {
    // 特殊处理导出
    let showMessage = true;
    if (res.data?.type) {
      if (res.data?.type === "application/json") {
        const reader = new FileReader();
        reader.readAsText(res.data, "utf-8");
        reader.onload = function () {
          let result;
          if (typeof reader.result === "string") {
            result = JSON.parse(reader.result);
          }
          const { code, message } = result;
          if (code !== 20000 && showMessage) {
            showMessage = false;
            ElMessage.error(transformyI18n(message));
            const timeout = setTimeout(() => {
              showMessage = true;
              clearTimeout(timeout);
            });
          }
        };
        return;
      } else {
        return res.data;
      }
    }
  }
}

// request interceptor
service.interceptors.request.use(
  config =>
    config.params && config.params.withoutToken
      ? requestInterceptorWithoutToken(config)
      : requestInterceptor(config),
  error => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (response.status === 200 || response.status === 304) {
      handleAuthError(res);
      return res;
    } else if (response.status === 401) {
      // 调用授权中心进行授权
      $store.dispatch("user/resetToken");
      showConfirm(
        () => {
          gotoAuthCenter();
        },
        transformyI18n("温馨提示"),
        transformyI18n("需要您重新登录！")
      );
      // handleAuthError(res);
      return Promise.reject(new Error("Error"));
    } else {
      handleAuthError(res);
      return Promise.reject(new Error(res.message || "Error"));
    }
  },
  error => {
    if (error.response) {
      const { errorMessage } = error.response.data;
      handleAuthError(errorMessage);
    } else {
      handleAuthError(error);
    }
    return Promise.reject(error);
  }
);

export default service;
