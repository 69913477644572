import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const accountCenterRouter = {
  path: "/account-center",
  name: "accountCenter",
  component: Layout,
  redirect: "/account-center/user",
  meta: {
    icon: "5",
    title: "accountCenter.name",
    rank: 9,
    roles: ["koms.user", "koms.role"] // "koms.system.role"
  },
  children: [
    {
      path: "/account-center/user",
      name: "accountCenterUser",
      component: () => import("/@/views/account-center/user/index.vue"),
      meta: {
        title: "accountCenter.user",
        roles: ["koms.user"]
      },
      hiddenChild: true, // 导航不显示下级
      children: [
        // {
        //   meta: { title: "accountCenter.user" },
        //   path: "/account-center/user",
        //   component: () => import("/@/views/account-center/user.vue"),
        //   name: "accountCenterUser"
        // },
        {
          meta: { title: "accountCenter.detail", showLink: false },
          path: "/account-center/detail",
          component: () =>
            import("/src/views/account-center/user/add-or-edit.vue"),
          name: "addUser"
        }
      ]
    },
    {
      path: "/account-center/role",
      name: "accountCenterRole",
      component: () => import("/@/views/account-center/role/index.vue"),
      meta: {
        title: "accountCenter.role",
        roles: ["koms.role"]
      },
      hiddenChild: true, // 导航不显示下级
      children: [
        {
          meta: { title: "accountCenter.addRole", showLink: false,keepAlive: true, },
          path: "/account-center/role/add",
          component: () =>
            import("/src/views/account-center/role/add-or-edit.vue"),
          name: "addRole"
        },
        {
          meta: { title: "accountCenter.roleDetail", showLink: false,keepAlive: true, },
          path: "/account-center/role/detail",
          component: () =>
            import("/src/views/account-center/role/add-or-edit.vue"),
          name: "roleDetail"
        }
      ]
    }
  ]
};

export default accountCenterRouter;
