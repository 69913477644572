import Cookies from "js-cookie";
import { init } from "../api/home";

const TokenKey = 'Token'
const RefreshKey = 'Refresh-Token'
const CurrentKey = 'Current-Timestamp'
const ExpiresKey = 'Expires-Timestamp'
const StateCodeKey = 'State-Code'
const AuthCodeKey = 'Auth-Code'
const Roles = 'roles'
const AuthCount = 'Auth-Count'

// 获取token
export function getToken() {
  return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  // console.log('开始set了');

  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return sessionStorage.removeItem(TokenKey)
}

// 截取某一特定param的值
export function getParam(param: string) {
  var reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

export function getRefreshToken() {
  return sessionStorage.getItem(RefreshKey)
}

export function setRefreshToken(token: string) {
  return sessionStorage.setItem(RefreshKey, token)
}

export function removeRefreshToken() {
  return sessionStorage.removeItem(RefreshKey)
}

// 获取当前时间戳
export function getCurrentTimestamp() {
  return sessionStorage.getItem(CurrentKey)
}
// 设置当前时间戳
export function setCurrentTimestamp(time: any) {
  return sessionStorage.setItem(CurrentKey, time)
}

export function removeCurrentTimestamp() {
  return sessionStorage.removeItem(CurrentKey)
}

// 获取过期时间戳
export function getExpiresTimestamp() {
  return sessionStorage.getItem(ExpiresKey)
}
// 设置过期时间戳
export function setExpiresTimestamp(time: any) {
  return sessionStorage.setItem(ExpiresKey, time)
}

export function removeExpiresTimestamp() {
  return sessionStorage.removeItem(ExpiresKey)
}

// 获取路由code
export function getStateCode() {
  return sessionStorage.getItem(StateCodeKey)
}
// 设置路由code
export function setStateCodeKey(code: string) {
  return sessionStorage.setItem(StateCodeKey, code)
}

export function removeStateCodeKey() {
  return sessionStorage.removeItem(StateCodeKey)
}

// 获取授权code
export function getAuthCode() {
  return sessionStorage.getItem(AuthCodeKey)
}
// 设置授权code
export function setAuthCodeKey(code: string) {
  return sessionStorage.setItem(AuthCodeKey, code)
}
// 删除授权code
export function removeAuthCode() {
  return sessionStorage.removeItem(AuthCodeKey)
}
// 获取权限
export function getRoles() {
  return sessionStorage.getItem(Roles)
}
export function setRoles(roles: string) {
  return sessionStorage.setItem(Roles, roles)
}

// 授权次数
export function getAuthCount() {
  return sessionStorage.getItem(AuthCount) ? sessionStorage.getItem(AuthCount) : 0
}
export function setAuthCount(authCount) {
  return sessionStorage.setItem(AuthCount, authCount)
}

// 设置语言
export function setlanguage(value: string) {
  sessionStorage.setItem('locale', value)
}

// 设置语言
export function getLanguage() {
  return sessionStorage.getItem('locale')
}

export function getLocalMsTime() { // 获取本地毫秒时间戳
  return (new Date()).getTime()
}

//除法计算
export function accDiv(arg1, arg2) {
  let int1 = 0, int2 = 0, digits1, digits2
  try { digits1 = arg1.toString().split(".")[1].length } catch (e) { digits1 = 0 }
  try { digits2 = arg2.toString().split(".")[1].length } catch (e) { digits2 = 0 }

  int1 = Number(arg1.toString().replace(".", ""))
  int2 = Number(arg2.toString().replace(".", ""))
  return (int1 / int2) * Math.pow(10, digits2 - digits1)
}

export function changePrice(n) {
  return n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}
