import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const orderRouter = {
  path: "/order/admin",
  name: "orderAdmin",
  component: () => import("../../views/order/index.vue"),
  meta: {
    icon: "34",
    title: "订单管理",
    rank: 3,
    roles: ["order"]
  },
  hiddenChild: true, // 导航不显示下级
  children: [
    {
      path: "/order/add",
      name: "OrderAdd",
      component: () => import("/@/views/order/add-or-edit.vue"),
      meta: {
        title: $t("新增"),
        keepAlive: true,
        showLink: false,
        authority: ["order"]
      }
    },
    {
      path: "/order/detail",
      name: "OrderDetail",
      component: () => import("/@/views/order/detail.vue"),
      meta: {
        title: $t("详情"),
        keepAlive: true,
        showLink: false,
        authority: ["order"]
      }
    }
  ]
};

export default orderRouter;
