import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const settleRouter = {
  path: "/settle",
  component: Layout,
  redirect: "/settle/admin",
  meta: {
    icon: "33",
    title: $t("结算管理"),
    rank: 8,
    roles: ["koms.settlement","sttl.detail"]
  },
  children: [
    {
      path: "/settle/admin",
      name: "settleTitle",
      component: () => import("/@/views/settle-admin/detail.vue"),
      meta: {
        title: $t("结算明细"),
        showParent: true,
        roles: ["sttl.detail"]
      }
    },
    {
      path: "/settle/total",
      name: "settleTotal",
      component: () => import("/@/views/settle-admin/collect.vue"),
      meta: {
        title: $t("结算汇总"),
        showParent: true,
        roles: ["koms.settlement"]
      }
    }
  ]
};

export default settleRouter;
