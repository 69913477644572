{
  # 商户平台
  # 数据看板
  "数据看板": "Dashboard",
  "今日实时概况": "Live Overview",
  "(按自然日统计)": "(Statistics by live overview)",
  "更新": "Update",
  "终端数量": "Terminals",
  "活跃终端数": "Active Terminals",
  "商户数量": "Merchants",
  "交易笔数": "Transaction",
  "交易笔数占比": "The Proportion of Transactions",
  "交易金额": "Amount",
  "交易金额占比": "Percentage of transaction amount",
  "消费笔数趋势": "Transaction Trend",
  "消费金额趋势": "Amount Trend",
  "消费金额：": "Amount：{value}",
  "消费笔数：": "Number：{value}",
  "笔数": "Number",
  "占比": "Rate",
  "机构消费笔数占比": "Proportion of institutional Consumption",
  "金额": "Amount",
  "机构消费金额占比": "Proportion of Institutional Consumption Amount",
  # 数据报表
  "报表数据": "Report Data",
  "日期": "Date",
  "当天": "This Day",
  "上一天": "Last Day",
  "上周同天": "Same Day As Last Week",
  "本周": "This Week",
  "上周": "Last Week",
  "本月": "This Month",
  "当月": "This Month",
  "上月": "Last Month",
  "去年同月": "Same Month Last Year",
  "较上周同天": "Compared To Same Day As Last Week",
  "较上月": "Compared To Last Month",
  "较上周": "Compared To Last Week",
  "较昨日": "Compared To Last Day",
  "较去年同月": "Compared To Same Month Last Year",
  "消费金额排行": "Amount Ranking",
  "更多": "More",
  "前10排名": "Top 10",
  "排名变化": "Rank change",
  "商户名称": "Merchant Name",
  "前5排名": "Top 5",
  "终端ID": "TID",
  "消费笔数排行": "Consumption Ranking",
  "消费笔数": "Transaction",
  "所有正向消费的总金额": "The total amount of all positive consumption",
  "所有正向消费的总笔数": "The total number of all positive consumption",

  "=消费金额/消费笔数": "=Amount / Transaction",
  "第某周": "The {week} week",
  "所有负向退货的总金额": "The total amount of all negative returns",

  "所有负向退货的总笔数": "Total number of all negative returns",
  "暂无数据": "No Data",
  "关闭": "Close",
  "周一": "Monday",
  "周二": "Tuesday",
  "周三": "Wednesday",
  "周四": "Thursday",
  "周五": "Friday",
  "周六": "Saturday",
  "周日": "Sunday",
  "星期一": "Monday",
  "星期二": "Tuesday",
  "星期三": "Wednesday",
  "星期四": "Thursday",
  "星期五": "Friday",
  "星期六": "Saturday",
  "星期日": "Sunday",
  "日报": "Daily",
  "选择日期": "Select Date",
  "此处为结算日期": "Here is the settlement date",
  "请选择日期": "Please Select Date",
  "选择币种": "Select Currency",
  "请选择": "Please select",
  "重置": "Reset",
  "查询": "Search",
  "周报": "Weekly",
  "请选择周": "Please Select Week",
  "月报": "Monthly",
  "请选择月": "Please Select Month",
  "KPI概况": "KPI Overview",
  "消费金额": "Amount",
  "消费单均价": "Average Price",
  "消费单均价趋势": "Average Price Trend",
  "请先输入查询条件": "Please enter the query conditions",
  "当天消费金额": "Amount Spent On This Day",
  "当月消费金额": "Amount Spent On This Month",
  "本周消费金额": "Amount Spent On This Week",
  "上一天消费金额": "Amount Spent On Previous Day",
  "上月消费金额": "Amount Spent On Previous Month",
  "上周消费金额": "Amount Spent On Previous Week",
  "当天消费笔数": "Transaction On This Day",
  "当月消费笔数": "Transaction On This Month",
  "本周消费笔数": "Transaction On This Week",
  "上一天消费笔数": "Transaction On Previous Day",
  "上月消费笔数": "Transaction On Previous Month",
  "上周消费笔数": "Transaction On Previous Week",
  "当月消费单均价": "Average Price On This Month",
  "上月消费单均价": "Average Price On Last Month",
  "请填写所有查询条件": "Please enter all the query conditions",
  # 退货记录
  "商户交易订单号": "Merchant Order No.",
  "类型": "Type",
  "商户标识": "PMR",
  "终端标识": "Terminal ID",
  "厂商": "Vendor",
  "机型": "Model",
  "设备S/N": "Device S/N",
  "交易名称": "Transaction Name",
  "币种": "Currency",
  "状态": "Status",
  "交易时间": "Transaction Time",
  "操作员": "Operator",
  "操作": "Operation",
  "支付渠道": "Payment Channel",

  "时间": "Time",
  "开始日期": "Start Date",
  "结束日期": "End Date",
  "网关支付": "System Processed",
  "非网关支付": "Uploaded",
  "提交": "Submit",
  "交易币种": "Currency",
  "取消": "Cancel",
  "确认": "OK",
  "全部": "All",
  "成功": "Succeed",
  "失败": "Failed",
  "处理中": "Processing",
  "正常": "Regular",
  "已冲正": "Reversed",
  "已撤销": "Revoked",



  "clear":
    {
      "number": "Merchant Order No.",
      "name": "Merchant Name",
      "clearList": "Liquidation Info",
      "clearTotal": "Settlement Summary",
      "mid": "PMR",
      "tid": "TID",
      "sn": "Device S/N",
      "trade": "Transaction Name",
      "currency": "Currency",
      "total1": "Total transaction amount",
      "amount1": "Amount",
      "premium": "Fee",
      "total2": "Liquidation total",
      "amount2": "Liquidation net",
      "time": "Transaction Time",
      "person": "Liquidator",
      "day": "Liquidation Date",
      "search": "Search Merchant Name/PMR/TID/SN"
    },
  "accountCenter":
    {
      "name": "User Center",
      "user": "User",
      "edit": "Edit User",
      "add": "New User",
      "detail": "User details",
      "userName": "User",
      "names": "Name",
      "creator": "Creator",
      "gmtCreate": "Creation Time",
      "userNames": "User",
      "email": "Email",
      "phone": "Phone",
      "type": "Status",
      "role": "Role",
      "roleName": "Name",
      "roleDetail": "Role Detail",
      "addRole": "Add Role",
      "lastTime": "Last login",
      "upTime": "Last login time",
      "upIP": "Last login IP",
      "super": "Super Admin",
      "unSuper": "Other",
      "normal": "Regular",
      "choose": "Select store",
      "start": "Start Date",
      "end": "End Date",
      "max64": "Up to 64 bits",
      "max32": "Up to 32 bits",
      "max20": "6-20位",
      "max100": "Up to 100 bits",
      "max128": "Up to 128 bits",
      "export1": "Export",
      "export2": "Whether to export？",
      "error1": "Please enter vaild email",
      "error2": "Please enter a valid phone number",
      "error3": "Please enter uppercase and lowercase letters, numbers and underscores"
    },

  "ok": "Save",
  "cancel": "cancel",
  "confirm": "Confirm",
  "no": "Cancel",
  "close": "Close",
  "save": "Save",
  "success": "Operation Success",
  "input": "Please Input",
  "select": "Please Select",
  "add": "New",
  "edt": "Edit",
  "del": "Delete",
  "reset": "Reset",
  "freeze": "Disable",
  "unfreeze": "Enabled",
  "Inquire": "Search",
  "operate": "Operation",
  "checkAll": "Select All",

  # navbar

  "切换成中文": "Chinese(Simplify)",
  "切换成英文": "English",
  "交易分析": "Transaction Analysis",
  "经营报告": "Business Report",
  "交易流水": "Transaction Record",
  "选择币种：": "Select Currency：",
  "当前默认": "Current Default",
  "设为默认币种": "Set as Default Currency",
  "点击折叠": "Click to Collapse",
  "较上一天": "Compared To Last Day",

  # 新增
  "是否解冻": "Whether to Enable？",
  "是否冻结": "Whether to Disable？",
  "用户已冻结": "User is disabled",
  "用户已启用": "User is Enabled",
  "清算汇总": "Liquidation Summary",
  "启用中": "Activated",
  "已停用": "Disabled",
  "已注销": "Unregistered",
  "号": "th",

  "profile": "Personal Center",
  "out": "Logout",
  "outTime": "Request timed out, please try again",
  "success2": "Copied to clipboard",
  "showPwd": "Save password",
  "showPwd2": "Please keep your password safe",

  "门店数量": "Number of Stores",
  "活跃门店": "Active Store",
  "商户拥有的门店总数": "The merchant's total number of stores",
  "商户拥有的终端总数": "The merchant's total number of terminals",
  "当日产生交易的门店数量": "The number of stores that generated transactions on the day",
  "当日产生交易的终端数量": "The number of terminals that generated transactions on the day",
  "商户支持的所有交易币种": "All transaction currencies supported by the merchant",
  "消费金额高峰时段分析": "Analysis of consumption amount during peak hours",
  "时段名称": "Time period name",
  "时段区间": "Time period",
  "交易日排行": "Trading day Ranking",
  "门店排行": "Store Ranking",
  "门店名称": "Store Name",
  "终端排行": "Terminal Ranking",
  "消费笔数高峰时段分析": "Analysis of consumption peak hours",
  "高峰时段": "Peak Hours",
  "交易概况": "Transaction Overview",
  "交易趋势": "Trading Trend",
  "消费趋势分析": "Consumer Trend Analysis",
  "查看详情": "See details",
  "月度交易金额详情": "Monthly transaction amount details",
  "月度交易笔数详情": "Details of the number of monthly transactions",
  "月度交易单均价详情": "Details of the average monthly transaction price",
  "暂未完成对账，无法输出数据，请耐心等待！": "The reconciliation has not been completed yet, and the data cannot be output. Please wait patiently!",

  "渠道处理中": "Channel Processing",
  "系统处理中": "System Processing",
  "重发": "Resend",


  "请输入商户交易订单号": "Please enter the merchant transaction order no.",
  "商户订单号/商户标识/商户名称/终端标识/SN": "Merchant Order No./PMR/Merchant Name/Terminal ID/SN",



  "商户管理中心": "Merchant Management Center",
  "门店管理中心": "Store Management Center",
  "温馨提示": "Kind tips",
  "需要您重新登录！": "You need to log in again!",
  "首页": "Home",
  # "退货记录": "Refund Record",

  "点击展开": "Click to expand",
  "关联交易": "Related transaction",
  "门店名称...": "Store Name...",
  "该订单不支持退款": "This order is not refundable",
  "订单不存在": "Order does not exist",
  "是否重置用户登录密码": "Whether to reset the user login password",
  "暂无操作权限": "No operation permission",
  "交易日": "Trading day",
  "星期": "Week",
  "排名": "Rank",
  "交易日消费金额排行": "Transaction day consumption amount ranking",
  "交易日消费笔数排行": "Transaction day consumption number ranking",
  "终端消费金额排行": "Terminal consumption amount ranking",
  "终端消费笔数排行": "Terminal consumption number ranking",
  "门店消费金额排行": "Store consumption amount ranking",
  "门店消费笔数排行": "Store consumption number ranking",
  "原商户交易订单号": "Original Merchant Order No.",
  "商户管理员": "Merchant Admin",
  "门店管理员": "Store Admin",
  "门店操作员": "Store Operator",
  "门店超级管理员": "Store Super Admin",
  "商户超级管理员": "Merchant Super Admin",
  "汇总详情": "Summary Details",
  "支持币种": "Supported Currencies",
  "响应超时，请稍后再试": "The response timed out, please try again later",
  "八达通支付": "Octopus Payment",
  "八达通退款": "Octopus Refund",
  "返回登录": "Go back to login",
  "token失效或用户暂无权限，请确认后重试": "The token is invalid or the user has no permission. Please confirm and try again",
  "是否发起查询": "Whether to initiate a query?",
  "输入金额不能大于可退货金额": "Amount cannot be greater than refundable amount",
  "原商户订单号/商户标识/商户名称/终端标识/SN": "Original Merchant Order No./PMR/Merchant Name/Terminal ID/SN",
  "原商户订单号/商户标识/商户名称/终端标识": "Original Merchant Order No./PMR/Merchant Name/Terminal ID",
  "重新登录": "Log back in",
  "结算信息": "Liquidation Information",
  "订单号不存在": "The order no. does not exist",
  "登录超时": "Login Timeout",
  "八达通": "Octopus",
  "Octopus": "Octopus",
  "返回": "Back",
  "手机": "Phone",
  "请输入原商户交易订单号": "Please enter the original merchant transaction order no.",
  "商户不存在": "Merchant Not Exist",
  "门店不存在": "Store Not Exist",
  "八达通撤销": "Octopus VOID",
  "八达通查询": " Octopus Query",
  "更新密钥": "Update Key",
  "交换数据": "Exchange Data",
  "用户名已存在": "The UserName already exists",
  "手机号码已存在": "The Phone already exists",
  "手机号已存在": "The Phone already exists",
  "邮箱已存在": "The Email already exists",
  "请输入正数": "Please enter a positive number",
  "请输入正确格式": "Please enter the correct format",
  "回到顶部": "Back to top",
  "对账未完成": "The reconciliation is not complete",

  # 新增
  "是": "Yes",
  "否": "No",

  "确认分期计划": "Confirm Installment plan",
  "获取分期计划": "Get Installment plan",
  "分期状态": "Installment Status",
  "分期计划ID": "Installment Plan ID",
  "确认分期ID": "Confirm Installment ID",
  "分期期数": "Number of Installments",
  "还款周期": "Repayment Period",
  "分期总额": "Total Installments",
  "首期本金": "Down Payment",
  "首期手续费": "Initial Fee",
  "末期本金": "Final Principal",
  "末期手续费": "Final Fee",
  "分期总手续费": "Total Fee",
  "最多两位小数": "Two Decimal Places",
  "正在努力加载中...": "Struggling to load...",
  "已经撤销的交易无法发起退款": "Cancelled transactions cannot be refunded",
  "max17": "Up to 17 bits",
  "max48": "Up to 48 bits",
  "max1024": "Up to 1024 bits",

  "展开": "Expand",
  "收拢": "Collapse",
  "是否删除用户": "Whether to delete the user？",

  "修改初始密码": "Change Initial Password",
  "您已成功登录Klond 商户服务平台，为保证您的账号安全，您需要修改初始密码":
    "You have successfully logged into the Klond Merchant Service Platform. To ensure the security of your account,
    you need to change the initial password",
  "新密码": "New Password",
  "请输入新密码": "Please enter a new password",
  "确认密码": "Confirm Password",
  "请确认密码": "Please confirm the password",
  "格式错误": "Format Error",
  "请再次输入新密码": "Please enter the new password again",
  "两次输入不一致": "Passwords entered do not match",
  "密码修改成功": "Password changed successfully",
  "正在跳转至登录界面": "Redirecting to the login page",
  "密码最小长度为8位": "Minimum password length is 8 characters",
  "密码最大长度为16位": "Maximum password length is 16 characters",
  "必须包含字母、数字和特殊符号至少两种": "Must contain at least two of the following: letters, numbers, and special characters",
  "可使用特殊字符": "Special characters are allowed",
  "请输入": "Please input",
  "vPlanRef": "Plan Ref",
  "externalTransactionRef": "External Transaction Ref",
  "vPlanID": "Plan ID",
  "vPlanAcceptanceId": "PlanAcceptance ID",

  "待审核": "To be Reviewed",
  "审核驳回": "Review Reject",
  "审核通过": "Approved",
  "渠道": "Channel",
  "获取时区失败": "Failed to get time zone",
  "outtransactionno": "Out Transaction No.",
  "原商户订单号/商户标识/商户名称/终端标识/流水号/分期MID": "Original Merchant Order No./PMR/Merchant Name/Terminal ID/Out Transaction No./External Transaction Ref",


  "退货记录": "Plan Cancellation",
  # "退货": "Refund",
  # "部分退款": "Partial Plan Cancellation",
  # "退款成功": "Refund Succeed",
  # "退款失败": "Refund Failed",
  # "退货成功": "Refund successful",
  # "退货申请提交成功": "Refund request submitted successfully",
  # "退货失败": "Refund failed",
  # "退货申请提交失败，请重试": "Refund request submission failed, please try again",
  # "退货失败，请重试": "Refund failed, please try again",
  # "可退货金额": "Refundable Amount",
  # "分期退款": "Installment Refund",
  # "是否重新发起退货": "Whether to re-initiate refund",
  # "请输入退货金额": "Please enter the refund amount",
  # "重新发起退货": "Re-initiate refund",
  # "已退款": "Refunded",

  # "退货金额": "Refund Amount",
  # "退货笔数": "Refund",
  "退货": "Cancel",
  "部分退款": "Partial Cancel",
  "退款成功": "Cancel Succeed",
  "退款失败": "Cancel Failed",
  "退货成功": "Cancel successful",
  "退货申请提交成功": "Cancel request submitted successfully",
  "退货失败": "Cancel failed",
  "退货申请提交失败，请重试": "Cancel request submission failed, please try again",
  "退货失败，请重试": "Cancel failed, please try again",
  "可退货金额": "Cancellable Amount",
  "分期退款": "Plan Cancellation",
  "是否重新发起退货": "Whether to re-initiate cancel",
  "请输入退货金额": "Please enter the cancel amount",
  "重新发起退货": "Re-initiate cancel",
  "已退款": "Cancelled",

  # 报表相关
  "退货金额": "Cancel Amount",
  "退货笔数": "Cancel",
  "导出": "Export",
  "是否导出流水数据": "Whether to export transactions data?",
  "生成中，请在文件管理查看文件": "Building, please view the file in File management",
    "清除全部": "Clear All",
  "清除": "Clear",
  "是否清除全部记录": "Whether to clear all records",
  "文件管理": "File",
  "文件导出": "File Export",
  "文件名称": "File Name",
  "导出时间": "Export Time",
  "文件状态": "File Status",
  "总记录数": "Total",
  "下载": "Download",
  "是否下载文件": "Whether to download files",
  "生成成功": "Generated Successfully",
  "生成失败": "Generated Failed",
  "delete": "Delete",
  "delete_role_tip": "Are you sure to delete this role?",

  "订单管理": "Order",
  "收件人/订单金额/订单号/支付单号": "Recipient/Order Amount/Order No./Payment No.",
  "收件人": "Recipient",
  "订单金额": "Order Amount",
  "订单号": "Order No.",
  "支付单号": "Payment No.",
  "支付成功":"Success",
  "待支付":"Pending",
  "支付中":"In Payment",
  "支付失败":"Failed",
  "创建时间":"Creation Time",
  "新增": "Add",
  "邮件模板": "Email Template",
  "您有一封待支付的账单，账单金额为 ": "You have a bill to be paid for",
  "账单信息": "Bill Information",
  "订单编号": "Order No.",
  "最晚付款时间": "Deadline For Payment",
  "订单描述": "Order Description",
  "订单详情": "Order Details",
  "收款方信息": "Merchant Information",
  "商户地址": "Merchant Address",
  "定义商家LOGO": "Define Merchant Logo",
  "上传证件": "Upload Picture",
  "付款人": "Payer",
  "邮箱": "Email",
  "附件": "Attachment",
  "上传附件": "Upload Attachment",
  "支持扩展名": "Supported",
  "用于订单有效性，超过该时间，订单将失效不可支付": "For order validity, if the time is exceeded, the order will become invalid and cannot be paid.",
  "付款信息": "Order Information",
  "选择文件": "Select File",
  "JPG或PNG格式!": "JPG or PNG format!",
  "大小不超过2MB!": "Size does not exceed 2MB!",
  "是否导出数据": "Whether to export data?",
  "订单地址": "Order Address",
  "已复制到剪切板": "Copied to clipboard",
  "商家LOGO": "Merchant LOGO",
  "支付信息": "Pay Information",
  "支付时间": "Payment Time",
  "商户号": "PMR",
  "交易类型": "Transaction Type",
  "支付状态": "Payment Status",
  "详情": "Details",


"KlondPay商户服务平台": "KlondPay Merchant Service Platform",
"数据看板-汇总指标": "Data Dashboard - Summary Metrics",
"查询消费金额、笔数占比": "Inquiry Of Consumption Amount And Transaction Count Ratio",
"数据看板-交易趋势": "Data Dashboard - Transaction Trend",
"数据看板-基本信息饼图": "Data Dashboard - Basic Information Pie Chart",
"清算信息": "Settlement Information",
"结算汇总": "Settlement Summary",
"清结算分页": "Settlement Pagination",
"导出清算明细": "Export Settlement Details",
"清结算汇总详情": "Settlement Summary Details",
"查询清结算汇总": "Inquire Settlement Summary",
"分页查询流水": "Paginate Transaction Flow",
"根据商户交易订单号获取交易详情": "Get Transaction Details By Merchant Transaction Order No.",
"关联商户交易订单号": "Associate Merchant Transaction Order No.",
"分页查询退款订单列表": "Paginate Refund Order List",
"查询退款结果": "Inquire Refund Result",
"重新发起退款申请": "Resubmit Refund Request",
"提交退款申请": "Submit Refund Request",
"流水导出": "Export Transaction Flow",
"日报高峰时刻交易金额": "Daily Peak Transaction Amount Report",
"日报高峰时刻交易笔数": "Daily Peak Transaction Count Report",
"Kpi概况": "KPI Overview",
"终端消费数量排行": "Terminal Consumption Count Ranking",
"交易金额趋势,交易笔数趋势,单均价趋势": "Transaction Amount Trend, Transaction Count Trend, Single Average Price Trend",
"账户中心": "Account Center",
"用户管理": "User",
"新增用户": "New",
"修改用户": "Edit",
"获取用户列表": "Query List",
"启用用户": "Enable",
"冻结用户": "Disable",
"重置用户密码": "Reset User Password",
"角色管理": "Role",
"获取角色列表": "Query List",
"新增角色": "New",
"删除角色": "Delete",
"修改角色": "Edit",
"角色详情": "Details",
"查询导出列表": "Inquire Export List",
"删除导出历史": "Delete Export History",
"角色名称": "Role Name",
"在线订单": "Order",
"查看订单详情": "Details",
"新增在线订单": "New",
"查看订单列表": "Query List",
"微信支付": "Wechat Pay",
"支付宝支付": "Alipay Pay",
"大小不超过10MB!": "Size does not exceed 10MB!",
"一次只能上传一个文件": "Only one file can be uploaded at a time",
"请输入(不包含特殊字符)": "Please input (no special characters)",
"请输入金额": "Please input the amount",
"当前已有退货申请在处理中": "A return application is currently being processed",
"已失效": "Expired",
"结算管理": "Settlement",
"结算明细": "Settlement Detail",

"终端号(TID)": "Terminal ID",
"商户号(PMR)": "PMR",
"结算金额": "Settlement Amount",
"商户费率": "MDR",
"交易手续费": "Transaction Fee",
"结算净额": "Net Settlement",
"结算日期": "Settlement Date",
"操作成功": "Operation Success",
"是否重发邮件": "Whether to resend?",
"结算汇总列表": "Query List",
"结算汇总币种列表": "Query Currency List",
"结算汇总导出": "Export",
"结算明细列表": "Query List",
"结算明细币种列表": "Query Currency List",
"结算明细导出": "Export",
"重发邮件": "Resend Email",
"共": "Total",
"条分页": "",
"请先添加终端号": "Please add the terminal  first",

"商户超级管理员(koms)": "Merchant Super Administrator (Koms)",
"门店超级管理员(koms)": "Store Super Administrator (Koms)",
"商户管理员(koms)": "Merchant Administrator (Koms)",
"门店管理员(koms)": "Store Manager (Koms)",
"门店操作员(koms)": "Store Operator (Koms)",
"订单状态": "Order Status",
"生效中": "In Effect",
"正数，整数最多8位，小数最多2位": "Positive number, up to 8 digits for the integer part, 2 digits for the decimal part.",
 "流水详情": "Transaction Detail",
 "请输入正确的邮箱": "Please input vaild email",
 "导入与导出": "Import/Export",
 "删除用户": "Delete",
 "失效": "Expired",
 "klondPay商户服务平台": "KlondPay Merchant Service Platform",
  "分页查询用户": "Paging Query User",
  "重置密码": "Reset Password",
  "用户中心": "User Center",
  "交易流水详情": "Transaction Details",
   "交易日志详情": "Transaction Log Details",
   "原商户订单号": "Original Merchant Order No.",
   "补录款": "Supplementary payment",
   "冲销款": "Offset payment",
   "款项": "Payment",
   "退货记录详情": "Cancel History Details",
   "退货详情": "Cancel Details",
   "原交易详情": "Original Transaction Details",
   "冲销": "Charge Against",
   "款项类型": "Payment Type",
}
